<template>
  <vue-bottom-sheet ref="drProfileBottomSheet">
    <section class="d-flex flex-column align-items-center justify-content-center" style="min-height: 366px!important;">
      <v-avatar size="120" rounded="circle" class="mb-4">
        <v-img :lazy-src="require('@/assets/images/profile-circle.png')" v-if="doctor.pic" :src="doctor.pic" />
        <v-img v-else :src="require('@/assets/images/profile.jpg')" />
      </v-avatar>

      <span class="d-block f14 font-weight-bold">{{ doctor.firstname }} {{ doctor.lastname }}</span>
      <span class="f13 font-weight-light d-block my-1"
            v-if="doctor.specialties && doctor.specialties.length" >{{ doctor.specialties[0].value }}</span>
      <span class="f13 font-weight-light text-blue d-block" v-if="doctor.clinic">{{ doctor.clinic.name }}</span>

      <div class="d-flex mt-4">
        <v-btn class="mx-2 rounded-lg" elevation="0" color="primary" @click="goToProfile(doctor.id)">{{$t("Profile")}}</v-btn>
        <v-btn class="mx-2 rounded-lg" elevation="0"
               v-if="!fromDoctorApp && !doctor.isFollowed" @click="follow(doctor)">{{$t("Follow")}}</v-btn>
        <v-btn class="mx-2 rounded-lg" elevation="0" color="primary"
               v-else-if="!fromDoctorApp && doctor.isFollowed" @click="unfollow(doctor)">{{$t("Followed")}}</v-btn>
      </div>

      <p class="f14 text-justify my-4">
        {{ doctor.bio }}
      </p>
    </section>
  </vue-bottom-sheet>
</template>

<script>
import apiService from "@/http/ApiService";

export default {
  name: "DoctorProfile",
  props: {
     fromDoctorApp: {type: Boolean, default: false},
   },
  data() {
    return {
      doctor: {
        firstname: '',
        lastname: '',
        specialties: [
          {
            value: ''
          }
        ],
        clinic: {
          name: ''
        }
      },
    };
  },
  methods: {
    openWithDoctorId(id) {
      this.$refs.drProfileBottomSheet.open();
      this.getDoctorDetailWithId(id);
    },

    openWithDoctorMobile(mobile) {      
      this.$refs.drProfileBottomSheet.open();
      this.getDoctorDetailWithMobile(mobile);
    },

    close() {
      this.$refs.drProfileBottomSheet.close();
    },

    getDoctorDetailWithId(id){
      apiService.getDoctorInfoById(id)
        .then((response) => {
          this.doctor = {
            firstname: '',
            lastname: '',
            specialties: [
              {
                value: ''
              }
            ],
            clinic: {
              name: ''
            }
          };

          this.doctor = response.data;
        });
    },

    getDoctorDetailWithMobile(mobile){
      apiService.getDoctorInfoByMobile(mobile)
        .then((response) => {
          this.doctor = {
            firstname: '',
            lastname: '',
            specialties: [
              {
                value: ''
              }
            ],
            clinic: {
              name: ''
            }
          };

          this.doctor = response.data;
        });
    },

    goToProfile(id){
      if(this.fromDoctorApp) {
        this.$router.push(`/s/specialist/${id}`)
      }
      else {
        this.$router.push(`/c/specialist/${id}`)
      }
    },

    follow(doctor){
      if(!this.fromDoctorApp) {
        apiService.setDoctorFollow(doctor.id, this.$store.getters.clientId)
        .then(() => {
          this.doctor.isFollowed = true;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
        });
      }
    },
    unfollow(doctor){
      if(!this.fromDoctorApp) {
        this.$swal({
          icon: "warning",
          showDenyButton: true,
          text: this.$t("Are you sure you want to unfollow the specialist?"),
          confirmButtonText: this.$t('Yes'),
          denyButtonText: this.$t('No'),
        })
        .then((result) => {
          if (result.isConfirmed) {
            apiService.setDoctorUnFollow(doctor.id, this.$store.getters.clientId)
            .then(() => {
              this.doctor.isFollowed = false;
            })
            .catch((err) => {
              this.$swal({
                icon: "warning",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t('OK'),
              })
            });
          }
        });
      }
    },
  }
};
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-bottom-sheet", { ref: "drProfileBottomSheet" }, [
    _c(
      "section",
      {
        staticClass:
          "d-flex flex-column align-items-center justify-content-center",
        staticStyle: { "min-height": "366px!important" },
      },
      [
        _c(
          "v-avatar",
          { staticClass: "mb-4", attrs: { size: "120", rounded: "circle" } },
          [
            _vm.doctor.pic
              ? _c("v-img", {
                  attrs: {
                    "lazy-src": require("@/assets/images/profile-circle.png"),
                    src: _vm.doctor.pic,
                  },
                })
              : _c("v-img", {
                  attrs: { src: require("@/assets/images/profile.jpg") },
                }),
          ],
          1
        ),
        _c("span", { staticClass: "d-block f14 font-weight-bold" }, [
          _vm._v(
            _vm._s(_vm.doctor.firstname) + " " + _vm._s(_vm.doctor.lastname)
          ),
        ]),
        _vm.doctor.specialties && _vm.doctor.specialties.length
          ? _c("span", { staticClass: "f13 font-weight-light d-block my-1" }, [
              _vm._v(_vm._s(_vm.doctor.specialties[0].value)),
            ])
          : _vm._e(),
        _vm.doctor.clinic
          ? _c(
              "span",
              { staticClass: "f13 font-weight-light text-blue d-block" },
              [_vm._v(_vm._s(_vm.doctor.clinic.name))]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "d-flex mt-4" },
          [
            _c(
              "v-btn",
              {
                staticClass: "mx-2 rounded-lg",
                attrs: { elevation: "0", color: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.goToProfile(_vm.doctor.id)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("Profile")))]
            ),
            !_vm.fromDoctorApp && !_vm.doctor.isFollowed
              ? _c(
                  "v-btn",
                  {
                    staticClass: "mx-2 rounded-lg",
                    attrs: { elevation: "0" },
                    on: {
                      click: function ($event) {
                        return _vm.follow(_vm.doctor)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Follow")))]
                )
              : !_vm.fromDoctorApp && _vm.doctor.isFollowed
              ? _c(
                  "v-btn",
                  {
                    staticClass: "mx-2 rounded-lg",
                    attrs: { elevation: "0", color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.unfollow(_vm.doctor)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("Followed")))]
                )
              : _vm._e(),
          ],
          1
        ),
        _c("p", { staticClass: "f14 text-justify my-4" }, [
          _vm._v(" " + _vm._s(_vm.doctor.bio) + " "),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }